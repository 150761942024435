
/*!
 *
 *  Web Starter Kit
 *  Copyright 2015 Google Inc. All rights reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *    https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License
 *
 */
/* eslint-env browser */


var self = this,
container, running = false,
currentY = 0,
targetY = 0,
oldY = 0,
maxScrollTop = 0,
minScrollTop, direction, onRenderCallback = null,
fricton = 0.85,
vy = 0,
stepAmt = 1,
minMovement = 0.1,
ts = 0.1;

var updateScrollTarget = function(amt) {
targetY += amt;
vy += (targetY - oldY) * stepAmt;

oldY = targetY;
}
var render = function() {
if (vy < -(minMovement) || vy > minMovement) {

  currentY = (currentY + vy);
  if (currentY > maxScrollTop) {
    currentY = vy = 0;
  } else if (currentY < minScrollTop) {
    vy = 0;
    currentY = minScrollTop;
  }

  container.scrollTop(-currentY);

  vy *= fricton;

  if (onRenderCallback) {
    onRenderCallback();
  }
}
}
var animateLoop = function() {
if (!running) return;
requestAnimFrame(animateLoop);
render();
//log("45","animateLoop","animateLoop", "",stop);
}
var onWheel = function(e) {
e.preventDefault();
var evt = e.originalEvent;

var delta = evt.detail ? evt.detail * -1 : evt.wheelDelta / 40;
var dir = delta < 0 ? -1 : 1;
if (dir != direction) {
  vy = 0;
  direction = dir;
}

//reset currentY in case non-wheel scroll has occurred (scrollbar drag, etc.)
currentY = -container.scrollTop();
updateScrollTarget(delta);
}

/*
* http://paulirish.com/2011/requestanimationframe-for-smart-animating/
*/
window.requestAnimFrame = (function() {
return window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.oRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  function(callback) {
    window.setTimeout(callback, 1000 / 60);
  };
})();

/*
* http://jsbin.com/iqafek/2/edit
*/
var normalizeWheelDelta = function() {
// Keep a distribution of observed values, and scale by the
// 33rd percentile.
var distribution = [],
  done = null,
  scale = 30;
return function(n) {
  // Zeroes don't count.
  if (n == 0) return n;
  // After 500 samples, we stop sampling and keep current factor.
  if (done != null) return n * done;
  var abs = Math.abs(n);
  // Insert value (sorted in ascending order).
  outer: do { // Just used for break goto
    for (var i = 0; i < distribution.length; ++i) {
      if (abs <= distribution[i]) {
        distribution.splice(i, 0, abs);
        break outer;
      }
    }
    distribution.push(abs);
  } while (false);
  // Factor is scale divided by 33rd percentile.
  var factor = scale / distribution[Math.floor(distribution.length / 3)];
  if (distribution.length == 500) done = factor;
  return n * factor;
};
}();


$.fn.smoothWheel = function() {
//  var args = [].splice.call(arguments, 0);
var options = jQuery.extend({}, arguments[0]);
return this.each(function(index, elm) {

  if (!('ontouchstart' in window)) {
    container = $(this);
    container.bind("mousewheel", onWheel);
    container.bind("DOMMouseScroll", onWheel);

    //set target/old/current Y to match current scroll position to prevent jump to top of container
    targetY = oldY = container.scrollTop();
    currentY = -targetY;

    minScrollTop = container.get(0).clientHeight - container.get(0).scrollHeight;
    if (options.onRender) {
      onRenderCallback = options.onRender;
    }
    if (options.remove) {
      log("122", "smoothWheel", "remove", "");
      running = false;
      container.unbind("mousewheel", onWheel);
      container.unbind("DOMMouseScroll", onWheel);
    } else if (!running) {
      running = true;
      animateLoop();
    }

  }
});
};

(function() {
  'use strict';

  // Check to make sure service workers are supported in the current browser,
  // and that the current page is accessed from a secure origin. Using a
  // service worker from an insecure origin will trigger JS console errors. See
  // http://www.chromium.org/Home/chromium-security/prefer-secure-origins-for-powerful-new-features
  var isLocalhost = Boolean(window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
    );

  if ('serviceWorker' in navigator &&
      (window.location.protocol === 'https:' || isLocalhost)) {
    navigator.serviceWorker.register('service-worker.js')
    .then(function(registration) {
      // updatefound is fired if service-worker.js changes.
      registration.onupdatefound = function() {
        // updatefound is also fired the very first time the SW is installed,
        // and there's no need to prompt for a reload at that point.
        // So check here to see if the page is already controlled,
        // i.e. whether there's an existing service worker.
        if (navigator.serviceWorker.controller) {
          // The updatefound event implies that registration.installing is set:
          // https://slightlyoff.github.io/ServiceWorker/spec/service_worker/index.html#service-worker-container-updatefound-event
          var installingWorker = registration.installing;

          installingWorker.onstatechange = function() {
            switch (installingWorker.state) {
              case 'installed':
                // At this point, the old content will have been purged and the
                // fresh content will have been added to the cache.
                // It's the perfect time to display a "New content is
                // available; please refresh." message in the page's interface.
                break;

              case 'redundant':
                throw new Error('The installing ' +
                                'service worker became redundant.');

              default:
                // Ignore
            }
          };
        }
      };
    }).catch(function(e) {
      console.error('Error during service worker registration:', e);
    });
  }

  $.fn.visible = function(partial) {
    
		var $t            = $(this),
			$w            = $(window),
			viewTop       = $w.scrollTop(),
			viewBottom    = viewTop + $w.height(),
			_top          = $t.offset().top - 100,
			_bottom       = _top + $t.height(),
			compareTop    = partial === true ? _bottom : _top,
			compareBottom = partial === true ? _top : _bottom;
	  
	  return ((compareBottom <= viewBottom));
	//   return ((compareBottom <= viewBottom) && (compareTop >= viewTop));
	};

  $(window).on('load', function(){
    $('html,body').animate({
      scrollTop: 0
    }, 500);
    
  });
	$(window).scroll(function(event) {
		$(".animated").each(function(i, el) {
			var el = $(el);
			if (el.visible(true)) {
			  el.addClass("inview"); 
			} else {
			  //el.removeClass("inview");
			}
    });
  });

  // Your custom JavaScript goes here
  $(document).ready(function(){

    //$(window).smoothWheel();

    setTimeout(function(){
      $('.home-page').addClass('inview');
    }, 3000);

    setTimeout(function(){
      $('.curtain').addClass('up');
      $('.curtain .mdl-spinner').css('opacity', 0);
    }, 3500);

    if ($('.section-hero').length){
			setTimeout(function(){
        $('.hero-slide.slide0 .animated').addClass('inview');
        $('.hero-list .animated').addClass('inview');
        $('.main-header .animated').addClass('inview');
        $('.section-hero .slick-dots').addClass('inview');
        $('.section-hero .section-next').addClass('inview');
      }, 3500);
      setTimeout(function(){
        $('.hero-slide.slide0').addClass('inview');
        $('.audio-btn').trigger('click');
        $('.section-hero').removeClass('zoomed');
			}, 4000);
    }else{
      setTimeout(function(){
        $('.main-header .animated').addClass('inview');
        $('.horizontal-page .animated').addClass('inview');
        $('.itembox-wrapper .itembox-box.animated').addClass('inview');
      }, 1000);
    }

    $('.audio-btn').click(function() {
      $(this).toggleClass("stopped playing");
        if ($(this).is('.stopped')){
          $('#audio-music')[0].pause();
          $(this).children('i').text('play_arrow');
          $(this).children('span').text('PLAY');
          $('#audio-icon').css('opacity', 1);
          $('#audio-bars').css('opacity', 0);
        } else {
          $('#audio-music')[0].play();
          $(this).children('i').text('stop');
          $(this).children('span').text('STOP');
          $('#audio-icon').css('opacity', 0);
          $('#audio-bars').css('opacity', 1);
        }
    }).addClass("stopped");

    $('.lang-wrap').hover(
      function(){
        $('.lang-switcher').addClass('open');
      },
      function(){
        $('.lang-switcher').removeClass('open');
      }
    );

    $('.hero-home').slick({
      // infinite: false,
      fade: true,
      dots: true,
      speed: 1000,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 5000,
      slickGoTo: true
    });
    $('.hero-home').on('afterChange', function(event, slick, currentSlide){
      var currentShow = $('.hero-slide.slide'+currentSlide);
      currentShow.addClass('inview');
      $('.hero-slide').not(currentShow).removeClass('inview');
    });
    // $('.hero-home').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    //   $('.hero-slide.slide'+currentSlide).removeClass('inview');
    //   $('.hero-slide.slide'+nextSlide).addClass('inview');
    // });
    $(".hero-list ul li").on('click', function(e){
        e.preventDefault();
        $(this).addClass('active');
        $(".hero-list ul li").not(this).removeClass('active');
        var slideIndex = $(this).attr('data-slide');
        var homeSlider = $( '.hero-home' );
        homeSlider.slick('slickGoTo', slideIndex);            
    });

    if($('.hero-home').length > 0){
      setTimeout(function(){
        setTimeout(function() {
            triggerClick('.hero-list ul li', 0)
        }, 5000);
        
        function triggerClick(selector, index) {
            var buttons = $(selector);
            var mod = index++ % buttons.length;
            $(buttons[mod]).trigger('click');
            setTimeout(function() {
                triggerClick(selector, index);
            }, 5000);
        }
      }, 4500);
    }
    
    $('.project-slider').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
      //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
      var i = (currentSlide ? currentSlide : 0) + 1;
      // $status.text('<span>'+i+ '</span> /' + slick.slideCount);
      $(this).siblings('.project-number').find('.number-current').text(i);
      $(this).siblings('.project-number').find('.number-all').text(slick.slideCount);
    });
    $('.project-slider').slick({
      infinite: false,
      fade: false,
      dots: false,
      speed: 1000,
      arrows: true,
      // autoplay: true,
      autoplaySpeed: 3000,
      slickGoTo: true
    });

    $('.project-nav button').click(function(){
      var triggerBtn = $(this).attr('c-for');
      var slickArrow = $(this).closest('.project-content').find('.project-slider .'+triggerBtn);
      var slickTitle = $(this).closest('.project-content').find('.project-title-slider .'+triggerBtn);
      slickArrow.trigger('click'); 
      slickTitle.trigger('click'); 
      if(slickArrow.hasClass('slick-disabled')){
        $(this).addClass('disabled');
        $(this).siblings('button').removeClass('disabled');
      }
      else{
        $(this).removeClass('disabled');
      }
    });

    $('.project-title-slider').slick({
      infinite: false,
      fade: false,
      dots: false,
      speed: 500,
      arrows: true,
      vertical: true
    });
    $('.project-names .tab-name button').click(function(){
      var btnAttr = $(this).attr('data-slide');
      $(this).closest('.project-content').find('.project-slider, .project-title-slider').slick('slickGoTo', btnAttr);
      $(this).addClass('active'); 
      $(this).closest('.project-content').find('.project-names .tab-name button').not(this).removeClass('active');
    });

    $('.tab-project button').click(function(){
      $(this).addClass('active');
      $('.tab-project button').not(this).removeClass('active');
      var tabProject = $('#'+$(this).attr('data-show'));
      tabProject.addClass('show');
      $('.project-content').not(tabProject).removeClass('show');
      $('.project-slider').slick('setPosition', 0);
    });

    $('select').niceSelect();

    $('.insta-slider').slick({
      dots: false,
      arrows: false,
      slidesToScroll: 1,
      slidesToShow: 5,
      infinite: false
    });
    $('.promo-line').slick({
      dots: false,
      arrows: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      slickGoTo: true
    });
    $('.promo-line-nav button').click(function(){
      var triggerBtn = $(this).attr('c-for');
      $('.promo-line').find('.'+triggerBtn).trigger('click');
    });

    $('.product-reviews').slick({
      dots: true,
      arrows: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 3000
    });
      
    $('.btn-scroll').click(function(e){
      e.preventDefault();
      var scrollTarget = $($(this).attr('href'));
			$('html, body').animate({
				scrollTop: scrollTarget.offset().top
			}, 1500);
    });

    $('.btn-toggle').click(function(e){
      e.preventDefault();
      var targetId = $('#'+$(this).attr('show-for'));
      targetId.toggleClass('open');
      $('.toggled-item').not(targetId).removeClass('open');
      $(this).addClass('active');
      $('.btn-toggle').not(this).removeClass('active');
    });

    $('body').mouseup(function(e) 
    {
        var targetDiv = $(".h-dropdown");
        if (!targetDiv.is(e.target) && targetDiv.has(e.target).length === 0) 
        {
          targetDiv.removeClass('show');
        }
    });

    // $('#form-booking').submit(function() {
    //   $.ajax({
    //     type: 'POST',
    //     // url: $(this).attr('action'),
    //     data: $(this).serialize(),
    //     dataType: 'json',
    //     success: function(json) {
    //         if(json.type == 'success') {
    //             $('#success-message').fadeIn();
    //             $('#form-booking').fadeOut();
    //         } else{
                
    //         } 
    //     }
    //   })
    //   return false;
    // });
    $('#form-booking .btn-booking').click(function(){
      $('#success-message').fadeIn();
      $('#form-booking').fadeOut();
    });

    
    $('.content-list li').click(function(){
      var currentImg = $('#' + $(this).attr('show-img'));
      $(this).addClass('active');
      $(this).siblings('li').removeClass('active');
      currentImg.addClass('active');
      currentImg.siblings('img').removeClass('active');
    });

    $('.card-tabs a').click(function(e){
      e.preventDefault();
      var target = $(this).attr('href');
      $(this).parent().siblings().removeClass('active');
      $(this).parent().addClass('active');      
      $('.tabs-contents').removeClass('tabs-active');
      $('.tabs-contents .animated').removeClass('inview');
      $('.tabs-contents .animated').removeClass(function(index, css) {
        return (css.match(/(d-[0-9][0-9])/g) || []).join(' ');
      });
      $(target).addClass('tabs-active');
      setTimeout(function(){
        $(target).find('.animated').addClass('inview delay1');
      }, 100)
      
    });

    $('.expandable-list .toggler').click(function(){
      $(this).closest('.expandable-list').toggleClass('open');
    });

    $('.btn-dialog').click(function(e){
      e.preventDefault();
      var currentDialog = $('#'+ $(this).attr('show-dialog'));
      currentDialog.addClass('open');
      $('.mdl-dialog-wrap').not(currentDialog).removeClass('open');
    });
    $('.btn-close').click(function(){
      $(this).closest('.mdl-dialog-wrap').removeClass('open');
    });
    $('#close-sitemap').click(function(){
      $('#sitemap-popup').addClass('close-trans');
      setTimeout(function(){
        $('#sitemap-popup').removeClass('open');
      }, 1000);
      setTimeout(function(){
        $('#sitemap-popup').removeClass('close-trans');
      }, 1500);
    });

    $('.map-filter input[type="checkbox"]').change(function() {
			var markerCategory = $(this).attr('id');
			if(this.checked) {
				$('.map-markers .marker[marker-type="'+markerCategory+'"]').addClass('show');
			}
			else{
				$('.map-markers .marker[marker-type="'+markerCategory+'"]').removeClass('show');
			}
    });
    
    if($(window).innerWidth() <= 768){
      $('#swipable-up').on('swipeup', function(){
        $(this).addClass('open');
      });
      $('#swipable-up').on('swipedown', function(){
        $(this).removeClass('open');
      });
      $('#swipable-up').on('click', function(){
        $(this).toggleClass('open');
      });
    }

    if($('.horizontal-page').length > 0){
      $('header.white-header').css('position', 'fixed');
      var productItem = $('.product-wrapper').length;
      var productWidth = $('.product-wrapper').width();
      $('.product-page .right-side').width(productItem * productWidth);

      function replaceVerticalScrollByHorizontal(event) {
        if (event.deltaY != 0) {
          window.scroll(window.scrollX + event.deltaY * 5, window.scrollY);
          
          event.preventDefault();
        }
        return;
      }
      if ($(window).innerWidth() > 768){
        window.addEventListener('wheel', replaceVerticalScrollByHorizontal);
      }else{}
      
    }
    
    $('#map-zoomin').click(function(){
        var mapImg = $('#map-img');
        var currWidth = mapImg.width();
        if(currWidth >= 1200){
            console.log("Maximum zoom-in level reached.");
        } else{
            mapImg.width((currWidth + 50) + "px");
        } 
    });
    $('#map-zoomout').click(function(){
        var mapImg = $('#map-img');
        var currWidth = mapImg.width();
        if(currWidth <= 750){
          console.log("Maximum zoom-out level reached.");
        } else{
            mapImg.width((currWidth - 50) + "px");
        }
    });
    $('#map-expand').click(function(){
      $('.maps-wrapper').toggleClass('expanded');
    });
  });

  if ($('.product-thumbnails').length > 0) {
    var tRange = document.getElementById('thumbnail-range')
    tRange.oninput = function() {
      var scrollThumbnail = document.getElementById("product-thumbnails-wrap");
      
      var total = scrollThumbnail.scrollWidth - scrollThumbnail.offsetWidth;
      var percentage = total*(this.value/100);
      
      console.log(total);
      scrollThumbnail.scrollLeft = percentage;
    }
  }
  
})();


// console.clear();
